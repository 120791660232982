<template>
    <div class="stepper-wrapper">
      <div v-for="(step, index) in steps" :key="index" class="stepper-item" :class="{ active: index === activeStep - 1 }">
        <div class="step-counter">
          {{ index + 1 }}
        </div>
        <div class="step-name">{{ step.title }}</div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      steps: {
        type: Array,
        required: true,
      },
      activeStep: {
        type: Number,
        required: true,
      },
    },
  }
  </script>
  
  <style>
.stepper-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 2rem auto 4rem;
  max-width: 900px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item:first-child .step-counter, .active .step-counter {
    background-color: rgb(95 46 234);
    color: #fff;
}

.stepper-item.active::before {
  border-color: rgb(95 46 234);
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

  </style>
  