<template>
      <div class="w-3/4 lcol">
        <div v-if="this.getCartTickets.length == 0">
          <span class="align-center w-100">Cosul este gol</span>
        </div>
        <div v-else>
            <div class="relative" style="border:1px solid #E4DAFF; border-radius:20px; padding:20px; margin-bottom: 0px; text-align: left; height: 100%;margin-bottom: 20px;" v-for="cartItem in this.getCartTickets" :key="cartItem.id">
              <div class="cartcontent">
                <div v-if="cartItem.dateVisibility == null || cartItem.dateVisibility == 0">
                  <p class="mb-2">{{ formatDate(cartItem.dateStart)}}</p>
                  <p>{{ formatEiHour(cartItem)}}</p>
                </div>
                <div v-if="cartItem.dateVisibility == 1">
                  <p class="mb-2">{{ formatEiDate(cartItem)}}</p>
                </div>
                <div v-if="cartItem.dateVisibility == 2">
                </div>
                <div>
                  <h3 class="ticket-title font-bold mb-2">{{ cartItem.name }}</h3>
                  <a @click="deleteTickets(cartItem)" class="delete">{{$t('delete')}}</a>
                    <ul>
                      <li class="py-1" v-for="(ticket, item) in cartItem.tickets" :key="item">
                        {{ capitalized(ticket.name) }} - {{ ticket.quantity }} x {{ ticket.price }} lei
                        <span style="float: right;">{{ ticket.quantity * ticket.price }} Lei</span>
                      </li>
                      <li style="font-weight: bold; margin-top: 10px;">
                        Total <span style="float: right;">{{ getEiPrice(cartItem) }} Lei</span>
                      </li>
                    </ul>
                </div>
              </div>
            </div>
            <div v-if="taxes != null" class="relative" style="border:1px solid #E4DAFF; border-radius:20px; padding:20px; margin-bottom: 0px; text-align: left; height: 100%; margin-bottom: 20px;">
                  <div>
                    <h3 class="font-bold mb-2">{{$t('tickets_total')}} <span style="float: right;">{{taxes.ticketsValue.toFixed(2)}} lei</span></h3>
                    <p class="py-1">{{$t('tax')}} <span style="float: right;">{{(taxes.total - taxes.ticketsValue).toFixed(2)}} lei</span></p>
                      <ul>
                        <li style="font-weight: bold; margin-top: 10px;">
                          {{$t('total_payment')}} <span style="float: right;">{{taxes.total.toFixed(2)}} lei</span>
                        </li>
                      </ul>
                  </div> 
            </div>

            <div class="grid grid-cols-2 md:grid-cols-2 gap-4 lg:hidden">
            <router-link to="/">
                <button
                  class="mt-3 font-bold px-2 py-1 flex align-center"
                  style="
                    border-radius: 20px;
                    padding: 10px 20px 8px;
                    border: 1px solid #5f2eea;
                    display: block;
                    width: 100%;
                    justify-content: center;
                  "
                >
                  <span class="mr-1">{{$t('continue_shopping')}}</span>
                </button>
              </router-link>
            <button
              class="mt-3 mb-2 bg-primary-muzeu text-white font-bold px-2 py-1 flex align-center"
              style="
                border-radius: 20px;
                padding: 10px 20px 8px;
                display: block;
                width: 100%;
                justify-content: center;
              "
              @click="nextStep"
            >
              <span class="mr-1">{{ $t("next_step") }}</span>
            </button>
            </div>


        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {formatDate, formatEiHour, formatEiDate} from '../mixins/dateformats'
import {formatDay} from '../mixins/dateformats'
import {formatHour} from '../mixins/dateformats'
import {formatYear} from '../mixins/dateformats'
import {capitalized} from '../mixins/utils'

export default {
    name: 'CosulMeu',
    props: {
      nextStep: {
        required: true
      }
    },
    data() {
      return {
        taxes: null
      };
    },
    computed: {
      ...mapGetters([
        'getCartTickets'
      ]),
      total() {
          return 0;
      }
    },
    methods: {
      formatDate,
      formatDay,
      formatHour,
      formatYear,
      formatEiHour,
      capitalized,
      formatEiDate,
      getEiPrice(ei) {
        let q = 0;
        ei.tickets.forEach( ticket => {
          q += ticket.quantity * ticket.price;
        });
        return q;
      },
      getTotalPrice(){
        let q = 0;
        this.getCartTickets.forEach(ei => {
            ei.tickets.forEach( ticket => {
            q += ticket.quantity * ticket.price;
          });
        })
        return q;
      },
      async deleteTickets(ei){
        let seatIds = [];
        ei.tickets.forEach(ticket => {
          ticket.seatIds.forEach(seatId => {
            seatIds.push(seatId);
          });
        });
        await this.$store.dispatch('removeFromCart',JSON.stringify(seatIds));
        this.taxes = await this.$store.dispatch('getTaxes');
      }
    },
    async created() {
      await this.$store.dispatch('getCart');
      this.taxes = await this.$store.dispatch('getTaxes');
    },
}
</script>