function resetError(field){
    field.error = null;
}
function validateMandatoryField(field){
  if (field.error == null && (field.value == null || (field.value instanceof String && field.value.trim().length == 0))){
    field.error = 'Câmpul este obligatoriu.';
  }
}
function validateSameValue(field1, field2){
  if (field2.error != null){
    return;
  }
  if (field1.value != field2.value){
    field2.error = 'Parolele nu se potrivesc.';
  } else {
    field2.error = null;
  }
}
function validateEmail(field) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const error = !emailPattern.test(field.value);
  if (error) {
    field.error = 'Email invalid.';
  }
}
function validatePhone(field) {
  const phoneRegex = /^[0-9]{8,14}$/;
  const isValid = phoneRegex.test(field.value);
  if (!isValid) {
    field.error = 'Numarul de telefon nu este valid.';
  }
}
function validateTrue(field) {
  if (!field.value) {
    field.error = 'Va rugam sa acceptati termenii si conditiile.';
  }
}

export {resetError, validateMandatoryField, validateSameValue, validateEmail, validatePhone, validateTrue}