<script>
import AppBanner from '@/components/shared/AppBanner';
import ListaMuzee from '../components/ListaMuzee.vue';
// import Button from '../components/reusable/Button.vue';

export default {
	name: 'Home',
	components: {
		AppBanner,
		ListaMuzee,
		// Button,
	},
	created() {
		// console.log(this.$i18n);
	}
};
</script>

<template>
		<!-- Banner -->
		<AppBanner class="mb-5 sm:mb-8" />
		<!-- Muzee -->
		<div class="container mx-auto">
		<ListaMuzee />
		</div>

		<!-- Load more projects button -->
		<!-- <div class="mt-10 sm:mt-20 flex justify-center">
			<router-link
				to="/projects"
				class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
				aria-label="More Projects"
			>
				<Button title="More Projects" />
			</router-link>
		</div> -->
</template>

<style scoped></style>
