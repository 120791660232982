export default {
    title: 'Portalul muzeelor din România',
    search: 'Cauta',
    all_states: 'Toate judetele',
    all_museums: 'Muzee disponibile',
    homepage_title: 'Portalul muzeelor din România',
    homepage_desc: 'Viziteaza Romania la un click distanta. Punem la un loc cele mai importante muzee din Romania.',
    cart: 'Cos de cumparaturi',
    no_tickets: 'Niciun bilet',
    checkout: 'Finalizeaza comanda',
    ticket: 'bilet',
    next_step: 'Pasul urmator',
    select_tickets: 'Selecteaza bilete',
    read_more: 'Citeste mai mult',
    read_less: 'Citeste mai putin',
    museums: 'Muzee',
    login: 'Autentificare',
    my_account: 'Contul meu',
    register: 'Cont nou',
    create_account: 'Creează cont',
    create_account_desc:'pentru acces facil la istoricul comenzilor tale',
    continue_as_guest: 'Continua fara cont',
    email: 'Adresa de email',
    phone: 'Telefon',
    firstName: 'Prenume',
    lastName: 'Nume',
    password: 'Parola',
    confirm_password: 'Confirma parola',
    accept_newsletter: 'Doresc sa ma abonez la newsletter',
    terms_conditions: 'termenele si conditiile',
    authentication: 'Autentificare',
    use_form_below: 'Foloseste formularul de mai jos pentru a te autentifica si a avea acces la biletele tale',
    email_address: 'Adresa de email',
    forgot_password: 'Am uitat parola',
    new_account: 'Cont Nou',
    new_user: 'Client nou',
    reset_password: 'Resetati parola',
    enter_email_to_reset_password: 'Introduceti adresa de email a contului pentru a reseta parola',
    reset_password_button: 'Reseteaza parola',
    follow_email_instructions: 'Urmareste instructiunile trimise pe email pentru a-ti reseta parola.',
    terms_and_conditions: 'Termeni si conditii',
    terms_intro: 'Prin achiziționarea unui bilet prin muzeu.ro sunteți de acord cu termenii și condițiile următoare, pe care vă obligați să le respectați:',
    terms_detail_1: 'biletul nu poate fi schimbat sau returnat indiferent de motivul invocat; în cazul în care evenimentul este anulat, contravaloarea biletelor va fi recuperată de la organizatorul evenimentului, muzeu.ro va depune toate eforturile să intermedieze acest transfer dar obligația pentru returnarea banilor aparține exclusiv organizatorului; pentru biletele achiziționate de la magazinele partenere, se trimite o cerere pe office[at]muzeu.ro in care se specifică numarul comenzii si se atasează o copie după bilete, în acest caz s-ar putea să fiți indrumați să ridicați personal banii de la acelasi magazin partener de la care ați achiziționat biletele; menționăm că indiferent de motivul anulării sau amânării evenimentului, suma ce va fi transferată va cuprinde doar contravaloarea biletelor returnate; contravaloarea biletelor va fi returnată în maxim 30 de zile de la solicitare. muzeu.ro nu poate fi făcută responsabilă de pagubele produse direct sau indirect clientului sau altor terți prin anularea sau amânarea evenimentului',
    terms_detail_2: 'in cazul in care exista suspiciuni de folosire de catre dumneavoastra a site-ului muzeu.ro in scopuri rau intentionate si/sau abuzive cum ar fi dar nelimitandu-se la blocarea locurilor in mod repetat fara finalizarea comenzii, crearea unor conturi multiple si folosirea lor in alt scop decat acela de achizitionare bilete, realizarea unor actiuni ce pot duce la incarcarea in mod excesiv si stresarea site-ului muzeu.ro, in aceste cazuri muzeu.ro poate sa va suspende temporar contul mergand pana la inchiderea lui in cazuri exceptionale. muzeu.ro nu va fi nevoita sa va anunte pentru deciziile luate si nici sa dea explicatii, acestea putand fi permanente. De asemenea muzeu.ro isi rezerva dreptul de a instiinta organele abilitate in legatura cu actiunile intreprinse de catre dumneavoastra',
    terms_detail_3: 'achiziționarea biletului presupune ca sunteți de acord cu termenii și condițiile impuse de organizatorul evenimentului indiferent de modalitatea în care sunt afișate sau transmise și pe care va obligați sa le respectati. Totodata este obligația dumneavoastră sa va interesați de ele și sa adresați întrebări direct organizatorului în caz de nelămuriri',
    terms_detail_4: 'serviciile oferite de muzeu.ro sunt doar de vânzare bilete; muzeu.ro nu are niciun control asupra evenimentului sau a locației în care se desfășoară evenimentul',
    terms_detail_5: 'muzeu.ro poate sa solicite o taxa de procesare pe care sunteti de acord sa o plătiți; aceasta reprezintă costurile necesare pentru procesarea comenzii dumneavoastra in condiții optime',
    terms_detail_6: 'muzeu.ro nu este responsabilă pentru acțiunile ce nu au legătură cu procedura de vânzare bilete',
    terms_detail_7: 'muzeu.ro nu poate fi acuzată pentru intrarea la eveniment cu bilete falsificate',
    terms_detail_8: 'daca biletele cumparate de la muzeu.ro vor fi revândute sau transferate în scopul obținerii de avantaje materiale de către o terță parte neautorizată de muzeu.ro, organizatorul de spectacol, agenții răspunzători cu supravegherea locației, cât și reprezentanții muzeu.ro pot refuza participarea deținătorilor biletelor la eveniment, inclusiv evacuarea lor din locație',
    terms_detail_9: 'daca nu este altfel specificat, biletul este valabil pentru accesul unei singure persoane la o reprezentație a evenimentului menționat pe bilet',
    terms_detail_10: 'organizatorul de spectacol are dreptul să modifice datele de desfășurare ale evenimentului, noile informații fiind anunțate prin mijloace de comunicare specifice;',
    terms_detail_11: 'asigurați-vă că ajungeți în timp util la eveniment astfel încât să intrați în locație înainte de ora de începere înscrisă pe bilet. Promoterul își rezervă dreptul de a interzice accesul dacă derularea evenimentului a început;',
    terms_detail_12: 'acest bilet nu poate fi dat ca premiu în cadrul niciunui concurs fără acordul prealabil al organizatorului de spectacol',
    terms_detail_13: 'muzeu.ro nu este responsabilă pentru prejudiciile aduse de către terți oricărui deținător de bilet achiziționat prin muzeu.ro',
    terms_detail_14: 'este interzis accesul la eveniment cu băuturi alcoolice, droguri, substanțe periculoase, recipiente de sticlă, aparate foto și video, obiecte contondente și arme de orice natură; deținătorii de bilete pot fi controlați la intrarea în locație pentru a se asigura respectarea regulilor anunțate',
    terms_detail_15: 'consumul de droguri sau de alte substante ilegale in locatia unde se desfasoara evenimentul constituie infractiune si va fi pedepsita conform prevederilor legale în vigoare',
    terms_detail_16: 'restricții în conformitate cu legile și reglementările privind protecția minorilor: persoanelor sub vârsta de 18 ani le este strict interzis accesul la evenimente ce pot avea un grad înalt de violență (cum ar fi meciurile de box etc.) și în locuri unde se servesc băuturi alcoolice sau se încurajează fumatul',
    terms_detail_17: 'achiziționarea biletului presupune că sunteți de acord cu termenii și condițiile afișate pe site-ul www.muzeu.ro cât și cu regulamentul organizatorului, termeni, condiții și regulament pe care vă obligați să le respectați',
    response_payment: 'Raspuns plata',
    payment_confirmed_congratulations: 'Plata a fost confirmata. Felicitari!',
    open_e_ticket: 'Deschide E-BILET',
    order_not_confirmed_error_message: 'Comanda nu a putut fi confirmata. Mesaj de eroare:',
    please_try_again_to_purchase_tickets: 'Te rugam sa incerci din nou achizitionarea de bilete.',
    museum_list: 'Lista muzee',
    back: 'Inapoi',
    orders: 'Comenzi',
    personal_info: 'Date peroanale',
    change_password: 'Schimba parola',
    exit: 'Iesire',
    no_orders: 'Nu ai nici o comanda',
    save: 'Salveaza',
    current_password: 'Parola curenta',
    new_password: 'Parola noua',
    delete: 'Șterge',
    tickets_total: 'Total bilete',
    tax: 'Taxe procesare comanda',
    total_payment: 'Total de plata',
    pay: 'Plateste',
    continue_shopping: 'Cumpara si alte bilete',
    tickets_email: 'Aici vei primi biletele',
    modify: 'Modifica',
    my_cart: 'Cosul meu',
    user_details: 'Detalii utilizaor',
    payment: 'Plata',
    terms: 'Termeni si conditii',
    gdpr: 'Termeni GDPR',
    last_tickets: 'Ultimele bilete',
    sold_out: 'Epuizat',
    pick_tickets: 'Alege bilete',
    Adult: 'Adult',
    Elev: 'Elev',
    Student: 'Student',
    Pensionar: 'Pensionar',
    'Castelul Peleș (expoziția de bază)': 'Castelul Peleș (expoziția de bază)',
    'Castelul Peleș (tur opțional 1)': 'Castelul Peleș (tur opțional 1)',
    'Castelul Pelișor': 'Castelul Pelișor'
}