<template>
  <div class="container mx-auto px-4 sm:py-8 sm:container sm:mx-auto">
    <div class="maintitlecont" style="border-bottom: 1px solid #cdcdcd">
      <h1 class="maintitle">Schimba parola</h1>
    </div>
    <div class="text-center" v-if="errorLink != null">
      <h2>Link incorect!</h2>
    </div>
    <div v-if="errorLink == null" class="content text-center">
      <loading
        class="vl-parent"
        color="#4e46dc"
        v-model:active="loading"
        transition="disappear"
        :can-cancel="false"
        :is-full-page="false"
      />
      <div class="text-center" style="max-width: 500px; margin: auto">
        <h2>Foloseste formularul de mai jos pentru a seta o noua parola</h2>
      </div>
      <div class="grid grid-cols-1 gap-4 text-left" style="margin-top: 50px">
        <div>
          <label class="block text-gray-700 font-bold mb-2" for="email">
            Parola
          </label>
          <input
            v-model.trim="change.password.value"
            :class="{ 'border-red': change.password.error }"
            class="border border-gray-400 p-2 w-full"
            type="password"
            required
          />
          <span v-if="change.password.error" class="alert">{{
            change.password.error
          }}</span>
        </div>
        <div>
          <label class="block text-gray-700 font-bold mb-2" for="email">
            Repeta parola
          </label>
          <input
            v-model.trim="change.confirm.value"
            :class="{ 'border-red': change.confirm.error }"
            class="border border-gray-400 p-2 w-full"
            type="password"
            required
          />
          <span v-if="change.confirm.error" class="alert">{{
            change.confirm.error
          }}</span>
        </div>
        <button
          @click="changePass"
          class="mt-3 mb-2 bg-primary-muzeu text-white font-bold px-2 py-1 flex align-center"
          style="
            border-radius: 20px;
            padding: 10px 20px 8px;
            display: block;
            width: 100%;
            justify-content: center;
          "
        >
          <span class="mr-1">Salveaza</span>
        </button>
        <span v-if="changeError" class="alert">{{ changeError }}</span>
        <span v-if="changeOk"
          >Parola setata cu succes. Foloseste link-ul de mai jos pentru a te
          autentifica.</span
        >
        <router-link to="/login"
          ><button
            class="mt-3 mb-2 font-bold px-2 py-1 flex align-center"
            style="
              border-radius: 20px;
              padding: 10px 20px 8px;
              border: 1px solid rgb(95, 46, 234);
              display: block;
              width: 100%;
              justify-content: center;
            "
          >
            <span class="mr-1">Autentificare</span>
          </button></router-link
        >
      </div>
    </div>
  </div>
</template>
  
  <script>
import { mapGetters, mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import {
  resetError,
  validateMandatoryField,
  validateSameValue,
} from "../mixins/validation";

export default {
  data() {
    return {
      loading: false,
      changeError: null,
      errorLink: null,
      changeOk: null,
      link: null,
      email: null,
      change: {
        password: {
          value: null,
          error: null,
        },
        confirm: {
          value: null,
          error: null,
        },
      },
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    validateSameValue,
    validateMandatoryField,
    ...mapActions(["changePassword"]),
    async changePass() {
      for (let field in this.change) {
        resetError(this.change[field]);
        validateMandatoryField(this.change[field]);
      }
      validateSameValue(this.change.password, this.change.confirm);
      let ok = true;
      for (let field in this.change) {
        if (this.change[field].error != null) {
          ok = false;
          break;
        }
      }
      if (ok) {
        this.loading = true;
        const response = await this.$store.dispatch("changePassword", {
          email: this.email,
          link: this.link,
          password: this.change.password.value,
        });
        if (response.result === 1) {
          this.changeOk = true;
        } else {
          // Display error message
          this.changeError = response.errors[response.errors.length - 1];
          this.changeOk = false;
        }
        this.loading = false;
      }
    },
  },
  async created() {
    try {
      //await this.$store.dispatch("fetchUser");
      this.user = this.getUser;
      if (this.getUser) {
        this.$router.push("/my-account"); // Redirect to my-account
      }
      this.link = this.$route.query.link;
      if (this.link == null) {
        this.errorLink = true;
      }
      this.email = this.$route.query.email;
      if (this.email == null) {
        this.errorLink = true;
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
  
  <style scoped>
.content {
  max-width: 500px;
  padding: 30px;
  margin: 0 auto;
}

input[type="text"],
input[type="email"],
input[type="phone"],
input[type="password"] {
  background: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
}

.alert {
  height: 48px;
  background-color: #ffc8c8;
  color: #e20000;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 40px;
  border-radius: 10px;
  margin-top: 10px;
}

.border-red {
  border-color: #e20000 !important;
}

label.border-red {
  border-bottom: 1px solid #e20000;
}

.alert::before {
  content: "!";
  position: absolute;
  display: flex;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  left: 10px;
  top: 13px;
  background-color: #ff0000;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #ffc8c8;
  align-items: center;
  justify-content: center;
}

.buttons {
  margin-top: 20px;
}
</style>
  