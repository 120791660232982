import axios from 'axios'
const config = require('../../config');
const state = {
  museums: [],
  pages: []
}

const getters = {
  getMuseums: state => state.museums,
  getPages: state => state.pages,
  getPageByLink:  (state) => (link) => {
    const page = state.pages.find( page => page.link === link);
    if (!page || !page.extraJson) {
      return null;
    }
    return page;
  },
  getMuseumsForPage:  (state) => (link) => {
    const page = state.pages.find( page => page.link === link);
    if (!page || !page.extraJson) {
      return null;
    }
    const slugs = JSON.parse(page.extraJson).slugs;
    if (!slugs) {
      return null;
    }
    const slugArr = slugs.split(",");
    let museums = state.museums.filter( museum => {
      const json = JSON.parse(museum.extraJson);
      museum.slug = json != null ? json.slug : '';
      return json != null && json.slug != null && slugArr.includes(json.slug);
    });
    return museums.sort((a, b) => {
      return slugArr.indexOf(a.slug) - slugArr.indexOf(b.slug);
    })
  }
}

const mutations = {
  setMuseums: (state, museums) => {
    state.museums = museums
  },
  setPages: (state, pages) => {
    state.pages = pages
  },
}

const actions = {
  async fetchMuseums ({ commit }) {
    try {
      const resp = await axios.post(`${config.baseUrl}?address=/spring/api/1.0.0/list/get/muzee`);
      if (resp.data.result == 1){
        commit('setMuseums', resp.data.data)
      }
    } catch(error){
      console.error("Error: " +  error);
    }
  },
  async fetchPages ({ commit }) {
    try {
      const resp = await axios.post(`${config.baseUrl}?address=/spring/api/1.0.0/list/get/pages`);
      if (resp.data.result == 1){
        commit('setPages', resp.data.data)
      }
    } catch(error){
      console.error("Error: " +  error);
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}