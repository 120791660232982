<template>
    <loading
    color="#4e46dc"
    v-model:active="loading"
    transition="disappear"
    :can-cancel="false"
    :is-full-page="true"
  />
  <div v-if="showingQr">
    <button @click="showingQr = false" class="mt-3 mb-2 font-bold px-2 py-1 flex align-center" style="border-radius: 20px; padding: 10px 20px 8px; border: 1px solid rgb(95, 46, 234); display: block; width: 100%; justify-content: center;"><span class="mr-1">Inapoi</span></button>
    <TicketDetails :acOrder="orderQr" :acInstance="instanceQr"/>
  </div>
  <div v-show="!showingQr" class="container mx-auto px-4 sm:py-8 sm:container sm:mx-auto">
    <div class="maintitlecont mb-4">
      <h1 class="maintitle">Comanda mea</h1>
    </div>
    <div class="flex myacc">
        <div class="w-full">
        <div class="text-center mt-8" v-if="order == null && !loading">Comanda inexistenta</div>
        <OrderDetails v-if="order != null" :order="order" :setShowingQr="setShowingQr" :setOrderQr="setOrderQr" :setInstanceQr="setInstanceQr"/>
        </div>
    </div>
  </div>
</template>
  
<script>

import { formatDateFull } from "../mixins/dateformats";
import Loading from "vue-loading-overlay";
import TicketDetails from "../components/TicketDetails.vue"
import OrderDetails from "../components/OrderDetails.vue"

export default {
  data() {
    return {
      order: null,
      showingQr: false,
      orderQr: null,
      instanceQr: null,
      loading: true
    }
  },
  components: {
    Loading,
    TicketDetails,
    OrderDetails
  },
  computed: {
  },
  methods: {
    formatDateFull,
    setShowingQr(value) {
      this.showingQr = value;
    },
    setOrderQr(value) {
      this.orderQr = value;
    },
    setInstanceQr(value) {
      this.instanceQr = value;
    },
  },
  async created() {
    if (this.$route.query.code != null) {
      this.loading = true;
      let orderResp = await this.$store.dispatch(
        "getOrder",
        this.$route.query.code
      );
      if (orderResp.result == 1) {
        this.order = orderResp.data;
        console.log(this.order);
      }
      this.loading = false;
    }
  }
}
</script>

<style scoped>
    .myacc {
    flex-wrap: wrap;
}
</style>