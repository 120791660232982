import Vuex from 'vuex'
import events from './modules/events'
import museums from './modules/museums'
import tickets from './modules/tickets'
import user from './modules/user'

export default new Vuex.Store({
  modules: {
    events,
    museums,
    tickets,
    user
  }
})