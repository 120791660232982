<template>
  <loading
    color="#4e46dc"
    v-model:active="loading"
    transition="disappear"
    :can-cancel="false"
    :is-full-page="true"
  />
  <div class="container mx-auto px-4 sm:py-8 sm:container sm:mx-auto">
    <div v-if="tickets == null && !loading">Comanda inexistenta</div>
    <div
      v-if="tickets.length > 0"
      class="slider-container"
      ref="sliderContainer"
    >
      <div
        class="slider"
        :style="{ transform: `translateX(-${currentSlide * 100}%)` }"
      >
        <div class="slide" v-for="(ticket, index) in tickets" :key="index">
          <div class="slide-content">
            <div class="ticket-info shadow-md">
              <div class="maintitlecont">
                <div class="tks-number">
                  BILET {{ currentSlide + 1 }} / {{ tickets.length }}
                </div>
              </div>
              <h2 class="mb-1 font-semibold">{{ ticket.title }}</h2>
              <p class="mb-1" v-if="instance.dateVisibility == null || instance.dateVisibility == 0">
                {{ formatEiDateFull(ticket) }}
              </p>
              <p class="mb-1" v-if="instance.dateVisibility == 1">
                {{ formatEiDate(ticket.date) }}
              </p>
              <p class="mb-1">Pret: {{ ticket.price }} Lei</p>
            </div>
            <div class="ticket-qr">
              <qrcode :text="ticket.qrCode" class="flex w-32 h-32"/>
            </div>
          </div>
        </div>
      </div>
      <button
        class="slider-prev"
        @click="prevSlide"
        :disabled="currentSlide === 0"
      >
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <button
        class="slider-next"
        @click="nextSlide"
        :disabled="currentSlide === tickets.length - 1"
      >
        <i class="fa-solid fa-chevron-right"></i>
      </button>
    </div>
  </div>
</template>
  
<script>
import Qrcode from "vue-qrcode-component";
import { formatEiDateFull, formatEiDate } from "../mixins/dateformats";
import Loading from "vue-loading-overlay";

export default {
  props: ["acOrder", "acInstance"],
  components: {
    Qrcode,
    Loading
  },
  data() {
    return {
      loading: false,
      order: null,
      instance: null,
      currentSlide: 0,
    };
  },
  methods: {
    formatEiDateFull,
    formatEiDate,
    prevSlide() {
      this.currentSlide =
        (this.currentSlide - 1 + this.tickets.length) % this.tickets.length;
    },
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.tickets.length;
    },
  },
  computed: {
    tickets() {
      let tickets = [];
      if (this.order == null) {
        return [];
      }
      let instances = [];
      if (this.instance != null) {
        instances.push(this.instance);
      } else {
        instances = this.order.ceiList;
      }

      for (let instance of instances) {
        for (let cat of instance.categories) {
          for (let t of cat.tickets) {
            tickets.push({
              title: instance.name,
              date: instance.date,
              dateVisibility: instance.dateVisibility,
              dateEnd: instance.dateEnd,
              price: t.fullPrice != null ? t.fullPrice : t.price,
              qrCode: t.barcode,
            });
          }
        }
      }
      return tickets;
    },
  },
  async created() {
    if (this.acOrder != null) {
      this.order = this.acOrder;
      this.instance = this.acInstance;
    } else if (this.$route.query.code != null) {
      this.loading = true;
      let orderResp = await this.$store.dispatch(
        "getOrder",
        this.$route.query.code
      );
      if (orderResp.result == 1) {
        this.order = orderResp.data;
      }
      this.loading = false;
    }
  },
  mounted() {
    if (this.tickets.length > 0) {
      const sliderContainer = this.$refs.sliderContainer;
      let touchStartX = 0;
      let touchEndX = 0;

      sliderContainer.addEventListener("touchstart", (event) => {
        touchStartX = event.touches[0].clientX;
      });

      sliderContainer.addEventListener("touchmove", (event) => {
        touchEndX = event.touches[0].clientX;
      });

      sliderContainer.addEventListener("touchend", () => {
        const diffX = touchEndX - touchStartX;

        if (diffX > 50) {
          this.prevSlide();
        } else if (diffX < -50) {
          this.nextSlide();
        }
      });
    }
  },
};
</script>
  
  <style scoped>
.slider-container {
  position: relative;
  max-width: 100% !important;
  margin: 0 auto;
  overflow: hidden;
}

.slider {
  display: flex;
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.slide {
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 30px;
  max-width: 800px;
}

.ticket-info {
  text-align: left;
  padding: 5px 20px 20px;
  background-color: #5f2eea;
  color: #fff;
  width: 100%;
  border-radius: 10px;
}

.ticket-qr {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-prev,
.slider-next {
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% -20px));
  padding: 8px 16px;
  font-size: 14px;
  background-color: #5f2eea;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.slider-prev {
  left: 16px;
}

.slider-next {
  right: 16px;
}


.ticket-qr {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ticket-qr img {
  max-width: 120px;
}

.tks-number {
  font-size: 14px;
  font-weight: bold;
  padding: 0px 0 10px;
}

.maintitlecont {
  padding: 10px 0;
}

@media (max-width: 767px) {
  .slider-container {
    max-width: 90%;
  }
  .maintitlecont {
    padding: 0;
  }

  .slider-container {
    margin-top: 40px;
  }
}
</style>
  