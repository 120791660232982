import moment from 'moment-timezone';

function formatDate (date) {
    return moment.tz(date, 'Europe/Bucharest').locale('ro').format('DD MMM');
}

function formatDateFull (date) {
    return moment.tz(date, 'Europe/Bucharest').locale('ro').format('DD MMM YYYY, HH:mm');
}

function formatHour (date) {
    return moment.tz(date, 'Europe/Bucharest').locale('ro').format('HH:mm');
}

 function formatDay (date) {
    return moment.tz(date, 'Europe/Bucharest').locale('ro').format('dddd');
}

function formatYear (date) {
    return moment.tz(date, 'Europe/Bucharest').locale('ro').format('YYYY');
}

function formatEiDateFull(instance) {
    fixEiDates(instance);
    if (instance.dateEnd == null) {
        return formatDay(instance.dateStart) + ', ' + formatDate(instance.dateStart) + ' ' + formatHour(instance.dateStart);
    } else {
        //todo - not same day
        return formatDay(instance.dateStart) + ', ' + formatDate(instance.dateStart) + ' ' + formatHour(instance.dateStart) + ' - ' + formatHour(instance.dateEnd);
    }
}

function formatEiHour(ei) {
    if (ei.dateEnd == null) {
        return formatHour (ei.dateStart);
    } else {
        return formatHour (ei.dateStart) + ' - ' + formatHour (ei.dateEnd);
    }
}

//05 dec. - 31 mart.
function formatEiDate (ei) {
    fixEiDates(ei);
    if (ei.dateEnd != null){
        if (!sameDay(new Date(ei.dateStart), new Date(ei.dateEnd))){
            return moment.tz(ei.dateStart, 'Europe/Bucharest').locale('ro').format('DD MMM') + ' - ' + moment.tz(ei.dateEnd, 'Europe/Bucharest').locale('ro').format('DD MMM');
        }
    }
    return formatDate(ei.dateStart);
}

function sameDay (ds, de) {
    return ds.getFullYear() === de.getFullYear() &&
    ds.getMonth() === de.getMonth() &&
    ds.getDate() === de.getDate();
}

function fixEiDates (instance){
    if (instance.dateStart == null && instance.date != null) {
        instance.dateStart = instance.date;
    }
}

export {formatDate, formatEiDateFull, formatDateFull, formatHour, formatEiHour, formatDay, formatYear, formatEiDate, sameDay}