<template>
  <div :text="currentLocale" right class="flex" style="cursor: pointer;">
    <div
        v-show="!isCurrentLocale('ro')"
      :disabled="isCurrentLocale('ro')"
      @click="onSetLocale('ro')"
    >
      RO
    </div>
    <div
    v-show="!isCurrentLocale('en')"
      :disabled="isCurrentLocale('en')"
      @click="onSetLocale('en')"
    >
      EN
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LanguageSwitcher',
  computed: {
    ...mapGetters([
        'getLocale'
      ]),
    currentLocale() {
      return this.$store.getters.getLocale.toUpperCase()
    }
  },
  created() {
  },
  methods: {
    onSetLocale(locale) {
      this.$i18n.locale = locale
      this.$store.commit('setLocale', locale);
    },
    isCurrentLocale(locale) {
      return this.$store.getters.getLocale === locale
    }
  }
}
</script>