<template>
  <div class="container mx-auto px-4 sm:py-8 sm:container sm:mx-auto">
    <div class="maintitlecont" style="border-bottom: 1px solid #cdcdcd">
      <h1 class="maintitle">{{$t('response_payment')}}</h1>
    </div>
    <div v-if="code == 1" class="content text-center">
      <div class="text-center" style="max-width: 500px; margin: auto">
        <h2>{{$t('payment_confirmed_congratulations')}}</h2>
      </div>
      <div class="grid grid-cols-1 gap-4 text-center" style="margin-top: 50px">
        <router-link :to="`/ticket-details?code=${tcode}`">
        <button
            class="mt-4 bg-primary-muzeu text-white font-bold px-2 py-1 ebiletBtn"
            style="border-radius: 20px; padding: 10px 30px"
          >
            {{$t('open_e_ticket')}}
          </button>
        </router-link>
      </div>
    </div>
    <div v-else class="content text-center">
      <div class="text-center" style="max-width: 500px; margin: auto">
        <h2>{{$t('order_not_confirmed_error_message')}} <b>{{msg}}</b></h2>
        <h2>{{$t('please_try_again_to_purchase_tickets')}}</h2>
      </div>
      <div class="grid grid-cols-1 gap-4 text-left" style="margin-top: 50px">
        <router-link to="/"
          ><button
            class="mt-3 mb-2 font-bold px-2 py-1 flex align-center"
            style="
              border-radius: 20px;
              padding: 10px 20px 8px;
              border: 1px solid rgb(95, 46, 234);
              display: block;
              width: 100%;
              justify-content: center;
            "
          >
            <span class="mr-1">{{$t('museum_list')}}</span>
          </button></router-link
        >
      </div>
    </div>
  </div>
</template>
  
  <script>

export default {
  data() {
    return {
      loading: false,
      tcode: null,
      code: null,
    };
  },
  components: {
  },
  computed: {
  },
  methods: {},
  created() {
    this.tcode = this.$route.query.tcode;
    this.code = this.$route.query.code;
    this.msg = this.$route.query.msg;

    if (this.code == 1 && this.tcode == null){
       this.$router.push("/");
    }

  },
};
</script>
  
  <style scoped>
.content {
  max-width: 500px;
  padding: 30px;
  margin: 0 auto;
}

.alert {
  height: 48px;
  background-color: #ffc8c8;
  color: #e20000;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 40px;
  border-radius: 10px;
  margin-top: 10px;
}

.buttons {
  margin-top: 20px;
}
</style>
  