<template>
  <div class="container mx-auto px-4 sm:py-8 sm:container sm:mx-auto">
    <div class="maintitlecont" style="border-bottom: 1px solid #CDCDCD;"><h1 class="maintitle">{{$t('authentication')}}</h1></div>
          <div class="content text-center">
              <div class="text-center" style="max-width: 500px;margin: auto;">
                  <h2>{{$t('use_form_below')}}</h2>
              </div>
              <div class="grid grid-cols-1 gap-4 text-left" style="margin-top:50px">
              <div>
                <label class="block text-gray-700 font-bold mb-2" for="email">
                  {{$t('email_address')}}
                </label>
                <input v-model.trim="login.email.value" :class="{ 'border-red': login.email.error }" class="border border-gray-400 p-2 w-full" type="email" name="email" id="email" required>
                <span v-if="login.email.error" class="alert">{{ login.email.error }}</span>
              </div>
              <div>
                <label class="block text-gray-700 font-bold mb-2" for="password">
                  {{$t('password')}}
                </label>
                <input v-model.trim="login.password.value" :class="{ 'border-red': login.password.error }" class="border border-gray-400 p-2 w-full" type="password" name="password" id="password" required>
                <span v-if="login.password.error" class="alert">{{ login.password.error }}</span>
                <router-link style="text-align:right; font-weight: 600; display: block; padding: 10px;" to="/reset-password"><span>{{$t('forgot_password')}}</span></router-link>
              </div>
              <button @click="loginUser" class="mt-3 mb-2 bg-primary-muzeu text-white font-bold px-2 py-1 flex align-center" style="border-radius: 20px; padding: 10px 20px 8px; display: block; width: 100%; justify-content: center;"><span class="mr-1">{{$t('authentication')}}</span></button>
              <span v-if="loginError" class="alert">{{ loginError }}</span>
              <router-link to="/register"><button class="mt-3 mb-2 font-bold px-2 py-1 flex align-center" style="border-radius: 20px; padding: 10px 20px 8px; border: 1px solid rgb(95, 46, 234); display: block; width: 100%; justify-content: center;"><span class="mr-1">{{$t('new_account')}}</span></button></router-link>
              </div>
          </div>
  </div>
</template>
  
  <script>
import { mapGetters, mapActions } from 'vuex';
  export default {
    data() {
      return {
        loginError: null,
        login: {
          email: {
            value: null,
            error: null
          },
          password: {
            value: null,
            error: null
          }
        },
      };
    },
    computed: {
      ...mapGetters([
        'getUser'
      ])
    },
    methods: {
      ...mapActions(['loginUser']),
      async loginUser() {
        this.loginError = null;
        for (let field in this.login){
          this.resetError(this.login[field]);
          this.validateMandatoryField(this.login[field]);
        }
        this.validateEmail(this.login.email);
        let ok = true;
        for (let field in this.login){
          if (this.login[field].error != null){
            ok = false;
            break;
          }
        }
        if (ok){
          const response = await this.$store.dispatch('loginUser', {
            username: this.login.email.value,
            password: this.login.password.value,
            remember: true
          });
          if (response.result === 1) {
            console.log(response.data);
            // Redirect to my-account page
            this.$router.push('/my-account');
          } else {
            // Display error message
            this.loginError = response.errors[response.errors.length-1];
          }
        }
    },
    resetError(field){
        field.error = null;
    },
    validateMandatoryField(field){
      if (field.error == null && (field.value == null || (field.value instanceof String && field.value.trim().length == 0))){
        field.error = 'Câmpul este obligatoriu.';
      }
    },
    validateEmail(field) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const error = !emailPattern.test(field.value);
      if (error) {
        field.error = 'Email invalid.';
      }
    },
  },
  async created() {
    try {
        await this.$store.dispatch('fetchUser');
        this.user = this.$store.getters.getUser;
        if (this.getUser) {
        this.$router.push('/my-account'); // Redirect to my-account
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
  
  <style scoped>

  .content {
    max-width: 500px;
    padding: 30px;
    margin: 0 auto;
  }

  input[type="text"], input[type="email"], input[type="phone"], input[type="password"] {
    background: #FFFFFF;
    border: 1px solid #CFCFCF;
    border-radius: 10px;
  }

  .alert {
    height: 48px;
    background-color: #FFC8C8;
    color: #E20000;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 40px;
    border-radius: 10px;
    margin-top: 10px;
  }

  .border-red {
    border-color: #E20000!important;
  }

  label.border-red {
    border-bottom: 1px solid #E20000;
  }

  .alert::before {
    content: '!';
    position: absolute;
    display: flex;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    left: 10px;
    top:13px;
    background-color: #FF0000;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #FFC8C8;
    align-items: center;
    justify-content: center;
  }

  
  .buttons {
    margin-top: 20px;
  }
  </style>
  