import { createApp } from 'vue';
import VCalendar from 'v-calendar';
import App from './App.vue';
import store from './store';
import router from './router';
import '@fortawesome/fontawesome-free/css/all.css';
import 'v-calendar/style.css';
import './assets/css/app.css';
import BackToTop from 'vue-backtotop';
import 'vue-loading-overlay/dist/css/index.css';
import { createHead } from '@vueuse/head'
import { createGtm } from '@gtm-support/vue-gtm';
import { createI18n } from 'vue-i18n'
import messages from './i18n'

const head = createHead()

const i18n = createI18n({
	locale: 'ro',
	messages: messages
})

const feather = require('feather-icons');
feather.replace();

createApp(App)
	.use(head)
	.use(router)
	.use(store)
	.use(VCalendar)
	.use(BackToTop)
	.use(i18n)
	.use(
		createGtm({
			id: "GTM-5KZSB6TC",
			vueRouter: router
		})
		)
	.mount('#app');